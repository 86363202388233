<!--
 * @Descripttion:修改专家信息
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-06 08:37:25
-->
<template>
	<div>
		<van-nav-bar title="专家管理" left-arrow @click-left="$router.push('/expert')" />
		<form>
			<div>
				<p>专家编号</p>
				<p><input type="text" v-model="expertData.num" /></p>
				<p>专家姓名<span style="color:red">*</span></p>
				<p><input type="text" v-model="expertData.name" /></p>
				<p>手机号<span style="color:red">*</span></p>
				<p><input type="text" v-model="expertData.mobilePhone" @blur="mobileOnBlur" /></p>
				<p>身份证号</p>
				<p><input type="text" v-model="expertData.idCard" @blur="onBlur" /></p>
				<p>性别</p>
				<p class="sex">
					<label for="male" class="inline mar-r" :class="{checkedLabel : expertData.sex =='1'}">男</label>
					<label for="female" disabled :class="{checkedLabel : expertData.sex =='2'}">女</label>
					<input type="radio" name="sex" value="1" id="male" v-model="expertData.sex" class="hide" />
					<input type="radio" name="sex" value="2" id="female" v-model="expertData.sex" class="hide" />
				</p>
				<p>出生日期</p>
				<p><input type="text" v-model="getBirthDay" readonly /></p>

				<p>地址</p>
				<p><input type="text" v-model="expertData.addr" /></p>
				<p>开户行</p>
				<p><input type="text" v-model="expertData.bankName" /></p>
				<p>银行卡号</p>
				<p><input type="text" v-model="expertData.bankCardNum" /></p>
			</div>
			<div class="w-line"></div>
			<div>
				<p>单位名称</p>
				<p><input type="text" v-model="expertData.orgName" /></p>
				<p>专家职称</p>
				<p><input type="text" v-model="expertData.expertTitle" /></p>
			</div>
			<div class="w-line" style="padding:0"></div>
			<div class="flex-between">
				<p>评审专业<span style="color:red">*</span></p>
				<button class="btn lh5" @click.prevent="$router.push('/review/expertEdit')">添加</button>
			</div>
			<div>
				<ul class="w-100">
					<li class="center van-ellipsis greenLi fw b1" v-for="item in expertData.majors" :key="item.id">{{item.parentName + "-" +showMajorAlias(item)}}</li>
				</ul>
			</div>
			<div class="w-line" style="padding:0"></div>
			<div>
				<textarea class="w-100" name="remark" v-model="expertData.remark" rows="3" placeholder="在这里填写备注内容…">{</textarea>
			</div>
			<div class="w-line" style="padding:0"></div>
			<div>
				<p>启用禁用</p>
				<p>
					<input type="radio" name="enable" v-model="expertData.enableStatus" value="0" id="enable" class="hide">
					<input type="radio" name="enable" v-model="expertData.enableStatus" value="1" id="disable" class="hide">
					<label for="enable" :class="{checkedLabel: expertData.enableStatus== '0'}">启用</label>
					<label for="disable" :class="{checkedLabel: expertData.enableStatus== '1'}" class="inline mar-l">禁用</label>
				</p>
			</div>
			<div><button class="btn w-100 fw f5" @click.prevent="onEditSave">保存</button></div>
		</form>
	</div>
</template>

<script>
	import {
		Radio,
		RadioGroup,
		Dialog
	} from 'vant'
	export default {

		data() {
			return {
				birthday: '',
				sex: '',
				idCard: '370214199504215270',
				showTime: false,
				nowDate: new Date(),
				expertData: {
					id: '',
					idCard: '',
					majors: [],
					num: '',
					name: '',
					sex: '',
					mobilePhone: '',
					addr: '',
					bankName: '',
					bankCardNum: '',
					orgName: '',
					expertTitle: '',
					remark: '',
					birthDate: '',
					enableStatus: '',
				},
				orgId: '', //公司id
				openId: '',
				mobileReg: /^1[3|4|5|7|8|9]\d{9}$/,
				/** 是否禁用 */
				isEnable: 1,
				queryParam: {
					orgId: "",
					expertId: ''
				},
				pageParams: {
					pageNo: 1,
					pageSize: 20
				},
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},

		components: {
			[Radio.name]: Radio,
			[Dialog.name]: Dialog,
			[RadioGroup.name]: RadioGroup,
		},

		watch: {
			idCard(newV) {
				if (newV == '') return
				if (newV.length < 15) return
			}
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$ls.get('edCurrentOrg')) {
				this.orgId = this.$ls.get('edCurrentOrg').id;
				this.queryParam.orgId = this.$ls.get('edCurrentOrg').id;
			}
			if (this.$ls.get('openid')) {
				this.openId = this.$ls.get('openid');
			}
			//获取编辑专家信息
			if (this.$ls.get('expertEdit_data')) {
				this.expertData = this.$ls.get('expertEdit_data');
				this.queryParam.expertId = this.expertData.id;
			}
			//如果
			if (this.$ls.get("orgExpertMajors")) {
				this.getExpertOpenMajors();
			} else {
				this.getTreeData();
			}

		},
		computed: {
			//获取出生日期
			getBirthDay() {
				if (!this.expertData.idCard) {
					return;
				} else {
					const idCardSize = this.expertData.idCard.length
					if (idCardSize == 15 || idCardSize == 18) {
						let birthday = ''
						if (idCardSize == 15) {
							birthday = '19' + this.expertData.idCard.substring(6, 8) + "-" + this.expertData.idCard.substring(8, 10) + "-" +
								this.expertData.idCard.substring(10, 12)
						} else if (idCardSize == 18) {
							birthday = this.expertData.idCard.substring(6, 10) + "-" + this.expertData.idCard.substring(10, 12) + "-" + this
								.expertData.idCard.substring(12, 14)
						}
						if (parseInt(this.expertData.idCard.substr(idCardSize - 2, 1)) % 2 == 0) this.expertData.sex = '2'
						else this.expertData.sex = '1'
						this.expertData.birthDate = birthday;
						return birthday;
					}
				}
			}
		},
		methods: {

			/**
			 * @Description: 监听身份证光标移出事件,并获取出生年月及性别
			 * @Author: Niklaus
			 * @Date: 2021-01-06 10:19:39
			 * @Remark： 不考虑超过100岁老人
			 */
			onBlur() {
				if (this.expertData.idCard == '') return
				const idCardSize = this.expertData.idCard.length
				if (idCardSize == 15 || idCardSize == 18) {
					let birthday = ''
					if (idCardSize == 15) {
						birthday = '19' + this.expertData.idCard.substring(6, 8) + "-" + this.expertData.idCard.substring(8, 10) + "-" +
							this.expertData.idCard.substring(10, 12)
					} else if (idCardSize == 18) {
						birthday = this.expertData.idCard.substring(6, 10) + "-" + this.expertData.idCard.substring(10, 12) + "-" + this.expertData
							.idCard.substring(12, 14)
					}
					if (parseInt(this.expertData.idCard.substr(idCardSize - 2, 1)) % 2 == 0) this.expertData.sex = '2'
					else this.expertData.sex = '1'
					this.expertData.birthday = birthday
				}
			},
			//遍历专业树结构，获取选中的数据
			getExpertOpenMajors() {
				let openMajors = [];
				let allData = this.$ls.get("orgExpertMajors");
				this.expertData.majors = [];
				if (this.$ls.get("orgExpertMajors") == '') {
					return;
				}
				if (allData.gongchengList.length > 0) {
					for (let i = 0; i < allData.gongchengList.length; i++) {
						let obj = allData.gongchengList[i];
						if (obj.children.length > 0) {
							for (let j = 0; j < obj.children.length; j++) {
								let childObj = obj.children[j];

								if (childObj.checked) {
									childObj.parentName = obj.majorName
									openMajors.push(childObj);
								}
							}
						}
					}
				};
				if (allData.huowuList.length > 0) {
					for (let i = 0; i < allData.huowuList.length; i++) {
						let obj = allData.huowuList[i];
						if (obj.children.length > 0) {
							for (let j = 0; j < obj.children.length; j++) {
								let childObj = obj.children[j];
								if (childObj.checked) {
									childObj.parentName = obj.majorName
									openMajors.push(childObj);
								}
							}
						}
					}
				};
				if (allData.fuwuList.length > 0) {
					for (let i = 0; i < allData.fuwuList.length; i++) {
						let obj = allData.fuwuList[i];
						if (obj.children.length > 0) {
							for (let j = 0; j < obj.children.length; j++) {
								let childObj = obj.children[j];
								if (childObj.checked) {
									childObj.parentName = obj.majorName
									openMajors.push(childObj);
								}
							}
						}
					}
				};
				this.expertData.majors = openMajors;
				this.$forceUpdate(); //强制刷新
			},
			//修改专家信息保存
			onEditSave() {
				if (this.onSaveCheck() == false) {
					return;
				}
				console.log("保存评审专业信息");
				this.expertData.orgId = this.orgId;
				this.expertData.openId = this.workweixin?this.openUserid:this.$ls.get("openid");
				this.$ajax({
					method: "put",
					url: "/gateway/ed/wx/wxEdExpertInfo/edit",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
					},
					data: this.expertData
				}).then((res) => {
					if (res.data.success) {
						this.$toast.success("修改专家成功")
					} else {
						this.$toast.fail(res.data.message)
					}
				});
			},

			onDelete() {
				Dialog.confirm({
					title: '删除',
					message: '确定删除该专家吗？',
					confirmButtonText: '删除',
					cancelButtonText: '取消',

				}).then(() => { //确认
					this.doDelete()
				}).catch(() => {
					console.log('取消')
				})
			},
			//删除专家
			doDelete() {
				var params = {};
				params.id = this.expertData.id; //专家主键
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				params.orgId = this.orgId; //机构id
				let accesstoken = this.$ls.get("token")
				this.$ajax({
					method: 'delete',
					url: '/gateway/ed/wx/wxEdExpertInfo/delete',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.$toast.success("删除成功");
						this.$router.push('/expert');
					} else {
						this.$toast.fail("删除失败")
					}
				});
			},
			//提交校验
			onSaveCheck() {
				if (this.expertData.num.trim() == '') {
					//专家编号为空暂不做处理
					// this.$toast.fail('请输入专家编号！');
					// return false;
				} else if (this.expertData.num.length > 50) {
					this.$toast.fail("专家编号过长，请修改！")
					return false
				}
				if (this.expertData.name.trim() == '') {
					this.$toast.fail('请输入专家姓名！');
					return false;
				} else if (this.expertData.name.length > 200) {
					this.$toast.fail("专家名称过长，请修改！")
					return false
				}
				// if(this.expertData.idCard.trim()==''){
				//身份证号为空暂不做处理
				//     this.$toast.fail('请输入身份证号！');
				//     return false;
				// }
				if (this.expertData.idCard.trim() != '' && this.validator(this.expertData.idCard) == false) {
					this.$toast.fail('身份证号格式不正确！');
					return false;
				}
				//手机号
				if (this.expertData.mobilePhone.trim() == '') {
					this.$toast.fail('请输入手机号！');
					return false;
				}
				// 手机号
				let re = new RegExp(this.mobileReg)
				if (!re.test(this.expertData.mobilePhone)) {
					this.$toast.fail("手机号不正确")
					return false;
				}
				if (this.expertData.addr.trim() == '') {
					//地址为空，暂不做处理
					// this.$toast.fail('请输入地址！');
					// return false;
				} else if (this.expertData.addr.length > 100) {
					this.$toast.fail("地址过长，请修改！")
					return false
				}
				if (this.expertData.orgName.trim() == '') {
					//单位为空，暂不做处理
					// this.$toast.fail('请输入单位名称！');
					// return false;
				} else if (this.expertData.orgName.length > 200) {
					this.$toast.fail("单位名称过长，请修改！")
					return false
				}
				if (this.expertData.bankName.length > 100) {
					this.$toast.fail("开户行名称过长，请修改！")
					return false
				}
				if (this.expertData.bankCardNum.length > 100) {
					this.$toast.fail("银行卡号过长，请修改！")
					return false
				}
				if (this.expertData.expertTitle.length > 200) {
					this.$toast.fail("专家职称过长，请修改！")
					return false
				}
				let majorList = this.expertData.majors; //评审专业
				if (majorList.length == 0) {
					this.$toast.fail('请选择评审专业！');
					return false;
				}
				return true;
			},
			// 校验函数返回 true 表示校验通过，false 表示不通过
			validator(val) {
				var reg = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/
				if (val.length == "15") {
					return reg.test(val)
				} else if (val.length == "18") {
					//只允许X大写
					// reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
					reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/
					return reg.test(val)
				} else {
					return false
				}
			},
			//手机号失去焦点函数
			mobileOnBlur() {
				if (!this.mobileValidator(this.expertData.mobilePhone)) {
					this.$toast.fail('请输入正确的手机号码')
					return
				}
			},
			//手机号码校验
			mobileValidator(val) {
				let mobileReg = new RegExp(/\d{3}-\d{8}|\d{4}-\d{7}|^1(3[0-9]|4[57]|5[0-35-9]|7[0678]|8[0-9])\d{8}$/)
				return mobileReg.test(val)
			},
			//优先显示备注
			showMajorAlias(item) {
				if (item.majorAlias != null && item.majorAlias != '') {
					return item.majorAlias
				} else {
					return item.majorName
				}
			},
			//获取专业树结构
			getTreeData() {
				var params = Object.assign({}, this.queryParam)
				params.pageNo = this.pageParams.pageNo;
				params.pageSize = this.pageParams.pageSize;
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let accesstoken = this.$ls.get("token")
				this.$ajax({
					methods: 'get',
					url: '/gateway/ed/wx/wxEdReviewMajorTwoThreeClassController/voByOrgExpertList/',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						let allData = {};
						allData.gongchengList = res.data.result.gongchengList == null ? [] : res.data.result.gongchengList;
						allData.huowuList = res.data.result.huowuList == null ? [] : res.data.result.huowuList;
						allData.fuwuList = res.data.result.fuwuList == null ? [] : res.data.result.fuwuList;
						this.$ls.set("orgExpertMajors", allData);
						this.getExpertOpenMajors();
					} else {
						this.$toast.fail("失败")
						console.log(res.data.message)
					}
				});
			},
			//下一个方法
		}
	}
</script>

<style scoped>
	form>div:not(:nth-child(2)) {
		padding: 0.3rem 0.5rem;
	}

	form>div>p {
		font-weight: bold;
		display: inline-block;
		line-height: 0.9rem;
	}

	form>div>p:nth-child(odd) {
		width: 2.5rem;
	}

	form>div>p:nth-child(even) {
		color: #737373;
		font-weight: 400;
		width: calc(100% - 2.5rem);
	}

	p>input {
		width: 100%;
	}

	.sex label {
		pointer-events: none;
	}

	label::before {
		width: 0.4rem;
		height: 0.4rem;
		border-radius: 50%;
		border: 1px solid gray;
		content: '';
		display: inline-block;
		vertical-align: text-top;
		margin-right: 0.2rem;
	}

	.checkedLabel::before {
		width: 0.45rem;
		height: 0.45rem;
		background: url(/static/img/icon/icon-checked.png) center/100% 100% no-repeat;
		border: none;
	}

	.greenLi:not(:first-child) {
		margin-top: 0.2rem;
	}
</style>
